/* eslint-disable react/no-danger */
import { useTranslation } from 'contexts/Localization'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Typewriter } from 'react-simple-typewriter'
import styled from 'styled-components'
import { Box, Flex } from 'widgets'
import { useInView } from 'react-intersection-observer'

import Vimeo from '@vimeo/player'
import { useDispatch, useSelector } from 'react-redux'
import { setShowMouse } from 'state/mouse/actions'
import CustomCursor from './CursorAnimate'

const Wrapper = styled.section`
  position: relative;
  height: 100vh;
  margin-bottom: 44px;
  background-color: #fff;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    background: url('images/home/video-img.png');
    background-size: cover;
    background-position: center;
    /* mix-blend-mode: screen; */

    @media (max-width: 768px) {
      background-position: 60%;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.39);
    }

    /* &.cursor-mouse {
      background-color: unset;
    } */

    iframe {
      width: 100vw;
      height: 100vw;
      min-height: 100vh;
      min-width: 177.77vh;
      position: relative;
      top: 50%;
      left: 50%;
      transform: perspective(1px) translate(-50%, -50%);
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 100%;
    }
  }

  .b-left {
    width: 100%;
    margin-top: 0rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh);

    position: fixed;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);

    ${({ theme }) => theme.mediaQueries.lg} {
      order: 1;
      height: calc(100vh);
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
    }

    .section-title {
      color: var(--Trng, #fff);
      font-size: 32px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 111;
      img {
        width: 70px;
      }
      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 42px;
        img {
          width: 89px;
        }
      }
    }
  }

  .pause-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    cursor: pointer;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
      img {
        filter: invert(78%) sepia(51%) saturate(5836%) hue-rotate(198deg) brightness(102%) contrast(90%);
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 5vh;
    right: 0;
    left: 0;
    padding: 0 20px;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.lg} {
      flex-direction: row;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      bottom: 10vh;
      position: absolute;
    }
  }

  .description {
    width: 100%;
    color: var(--Trng, #fff);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 83px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 40px;
      height: unset;
      display: flex;
      align-items: center;
    }
    .typing-box {
      width: 22px;
      height: 42px;
      display: inline-block;
      background-color: #fff;
    }
    .styles-module_blinking__9VXRT {
      animation-duration: 0.4s;
    }
  }

  .start-button {
    white-space: nowrap;
    display: inline-flex;
    padding: 5px 20px 5px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    /* background: rgba(0, 109, 254, 0.80); */
    backdrop-filter: blur(2px);
    transition: all 0.3s ease-in-out;
    border: 1px solid #fff;

    color: var(--wh, var(--Trng, #fff));
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    &:hover .image-button {
      img {
        &:first-child {
          transform: translateY(30px);
        }
        &:last-child {
          transform: translateY(0);
        }
      }
    }
    &:hover {
      /* background: var(--Gr-2, linear-gradient(143deg, #006DFE 21.44%, #43E8E1 126.28%)); */
    }

    .image-button {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
      overflow: hidden;
      position: relative;
      z-index: 2;

      img {
        transition: transform 0.3s ease-in-out;
        position: absolute;
        width: 15px;
        &:first-child {
          transform: translateY(0);
        }
        &:last-child {
          transform: translateY(-30px);
        }
      }
    }
  }

  .cursor-custom {
  }

  .content-cursor {
    img {
      filter: invert(1);
    }
  }
`

const linkVimeo = 'https://player.vimeo.com/video/887921231?h=d07b19f0f2&autoplay=1&controls=0&loop=1'

const Welcome = ({ scrollToElement }) => {
  const { t } = useTranslation()
  const [addBlur, setAddBlur] = useState(0)
  const iframeRef = useRef(null)
  const [vimeoPlayer, setVimeoPlayer] = useState(null)
  const [isPlay, setIsPlay] = useState(true)
  const { showMouse } = useSelector((state: any) => state.mouse)
  const [greeting, setGreeting] = useState('')
  const [currentImage, setCurrentImage] = useState('')
  const [isMuted, setIsMuted] = useState(true)
  const dispatch = useDispatch()
  const { ref, inView } = useInView({
    triggerOnce: false,
  })

  const setVolumeToZero = useCallback(() => {
    if (vimeoPlayer) {
      vimeoPlayer
        .getVolume()
        .then((volume) => {
          if (volume === 1) {
            vimeoPlayer.setVolume(0)
            setIsMuted(true)
          }
        })
        .catch((error) => {
          console.error('An error occurred while getting the volume: ', error)
        })
    }
  }, [vimeoPlayer])

  const handleScrollElement = useCallback(() => {
    setVolumeToZero()
    scrollToElement()
  }, [setVolumeToZero])

  const handleToggleFalse = () => {
    dispatch(setShowMouse(false))
  }

  const handleToggleTrue = () => {
    dispatch(setShowMouse(true))
  }

  const handleMuteToggle = () => {
    if (vimeoPlayer && showMouse) {
      if (isMuted) {
        vimeoPlayer.setVolume(1)
        setIsMuted(false)
      } else {
        vimeoPlayer.setVolume(0)
        setIsMuted(true)
      }
    }
  }

  const toggleVisible = useCallback(() => {
    window.requestAnimationFrame(() => {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 77 && scrolled < 800) {
        if (scrolled % 10 === 0) {
          const blurValue = (scrolled / 800) * 5
          setAddBlur(blurValue)
        }
      } else {
        setAddBlur(0)
      }
    })
  }, [])

  const handlePauseVideo = () => {
    if (vimeoPlayer) {
      if (isPlay) {
        vimeoPlayer.pause()
      } else {
        vimeoPlayer.play()
      }
      setIsPlay(!isPlay)
    } else {
      console.error('Vimeo player not initialized')
    }
  }

  useEffect(() => {
    const initializeVimeoPlayer = async () => {
      if (iframeRef.current) {
        const player = new Vimeo(iframeRef.current)
        // player.ready().then(() => {
        //   setVimeoPlayer(player);
        //   // player.on('play', () => {
        //   //   setIsVideoLoaded(false)
        //   // });
        // });
        player.setVolume(0)
        player.play()
        setVimeoPlayer(player)
      }
    }

    initializeVimeoPlayer()

    return () => {
      // Cleanup when the component unmounts
      if (vimeoPlayer) {
        vimeoPlayer.destroy()
        setVimeoPlayer(null)
      }
    }
  }, [vimeoPlayer])

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)

    return () => window.removeEventListener('scroll', toggleVisible)
  }, [toggleVisible])

  useEffect(() => {
    const currentTime = new Date().getHours()
    if (currentTime >= 0 && currentTime < 12) {
      setGreeting('sáng')
      setCurrentImage('/images/home/current-time/morning.svg')
    } else if (currentTime >= 12 && currentTime < 18) {
      setGreeting('chiều')
      setCurrentImage('/images/home/current-time/afternoon.svg')
    } else if (currentTime >= 18 || currentTime <= 23) {
      setCurrentImage('/images/home/current-time/night.svg')
      setGreeting('tối')
    }
  }, [])

  useEffect(() => {
    setVolumeToZero()
  }, [inView, setVolumeToZero])

  return (
    <>
      <Box style={{ filter: `blur(${addBlur}px)` }} onClick={handleMuteToggle} ref={ref}>
        <Wrapper>
          <Box className="bg">
            {/* <iframe ref={iframeRef} title="intro" src={`${linkVimeo}`} allow="autoplay; fullscreen" /> */}
          </Box>

          {/* <Box className="bg cursor-mouse">
            <CustomCursor>
              <Box className="content-cursor">
                {isMuted ? (
                  <img width={20} src="/images/icons/unmute.svg" alt="" />
                ) : (
                  <img width={20} src="/images/icons/mute.svg" alt="" />
                )}
              </Box>
            </CustomCursor>
          </Box> */}

          <Box className="container" zIndex={10}>
            <Flex className="flex ">
              <div className="b-left">
                <div className="section-title">
                  <img src={currentImage} alt="" />
                  <Box ml={3}>
                    {t('Chào buổi')} {t(greeting)}!
                  </Box>
                </div>
              </div>
            </Flex>

            <Box className="bottom-content">
              <p className="description">
                <Typewriter
                  words={[
                    t('Chào mừng đến với Soei Group'),
                    t('Hãy khám phá sản phẩm và tham gia cộng đồng của chúng tôi'),
                  ]}
                  loop={0}
                  cursor
                  cursorStyle={<div className="typing-box" />}
                  typeSpeed={100}
                  deleteSpeed={10}
                  delaySpeed={1000}
                />
              </p>

              <Flex flexDirection="column" alignItems="flex-end">
                {/* <Box
                  className="pause-button"
                  onClick={handlePauseVideo}
                  mb={3}
                  onMouseEnter={handleToggleFalse}
                  onMouseLeave={handleToggleTrue}
                >
                  {isPlay ? (
                    <img width={10} height={10} src="/images/home/pause.svg" alt="" />
                  ) : (
                    <img width={10} height={10} src="/images/home/play-button.svg" alt="" />
                  )}
                </Box> */}

                <Box
                  className="start-button pointer read-more-button"
                  onClick={handleScrollElement}
                  onMouseEnter={handleToggleFalse}
                  onMouseLeave={handleToggleTrue}
                >
                  <span aria-hidden="true" />

                  <Box className="image-button">
                    <img src="/images/home/right-start-button.svg" alt="" />
                    <img src="/images/home/checked-start-button.svg" alt="" />
                  </Box>

                  <Box ml={3} zIndex={3}>
                    {t('Bắt đầu')}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </>
  )
}

export default Welcome
