/* eslint-disable react/no-danger */
import { Col, Row } from 'antd'
import { useTranslation } from 'contexts/Localization'
import useIsMobile from 'hooks/useIsMobile'
import styled from 'styled-components'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Box, Flex } from 'widgets'

const ItemWrapper = styled.div`
  position: relative;
  min-height: 262px;
  height: 100%;
  transition: all 0.8s ease-in-out;
  height: 100%;

  .image-item{
    width: 100%;
    max-width: 536px;
    object-fit: cover;
    border-radius: 53px;
    overflow: hidden;
    border-radius: 30px;

  }

  .title{
    color: var(--Black, var(--en, #000));
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: normal;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 50px;
      line-height: 56px;
    }
  }

  .description{
    color: var(--Black, var(--en, #000));
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;

    margin: 20px 0 30px;
    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .read-more-button{
    cursor: pointer;
    width: fit-content;
    border-radius: 30px;
    border: 1px solid var(--Black, #000);
    color: var(--en, #000);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    line-height: normal;
    position: relative;
    overflow: hidden;

    span {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transform: translateX(-100%);
      transition: transform 275ms ease;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      overflow: hidden;
      &::before {
        display: inline-block;
        content: attr(data-content);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: var(--Main-blue, #006DFE);
        color: #fff;
        transform: translateX(100%);
        transition: transform 275ms ease;
      }
    }

    &:hover {
      border: 1px solid transparent;
      span {
        transform: translateX(0);
        &::before {
          transform: translateX(0);
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.md} {
      padding: 15px 40px;
      font-size: 16px;
    }
  }
`
const mobileWith = 852

const FeatureItem = ({ data, index }) => {
  const isMobile = useIsMobile(mobileWith)
  const { t } = useTranslation()

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const initial = { x: '-30vw', opacity: 0 };
  const animate = (inViewParam) => ({ x: inViewParam ? 0 : (index === 1 ? '30vw' : '-30vw'), opacity: inViewParam ? 1 : 0 });

  return (
    <ItemWrapper>
      <Row gutter={[20, 40]}>
        <Col span={24} md={12} order={(index === 1 && !isMobile) ? 2 : 1}>
          <Flex justifyContent={(index === 1 && !isMobile) ? 'flex-end' : 'flex-start'}>
            <motion.div
              ref={ref}
              initial={initial}
              animate={animate(inView)}
              transition={{ duration: 1.15 }}
            >
              <img className='image-item' src={data.thumbnail} alt='' />
            </motion.div>
          </Flex>
        </Col>

        <Col span={24} md={12} order={(index === 1 && !isMobile) ? 1 : 2}>
          <Box className='title' position='relative' ref={ref}>
            <motion.div
              ref={ref}
              initial={initial}
              animate={animate(inView)}
              transition={{ duration: 1.1 }}
            >
              {t(data.title)}
            </motion.div>
          </Box>

          <Box className='description'>
            <motion.div
              ref={ref}
              initial={initial}
              animate={animate(inView)}
              transition={{ duration: 1.15 }}
            >
              <div dangerouslySetInnerHTML={{ __html: t(data.description) }} />
            </motion.div>
          </Box>

          <motion.div
            ref={ref}
            initial={initial}
            animate={animate(inView)}
            transition={{ duration: 1.2 }}
          >
            <a href={data.link}>
              <Box className='read-more-button'>
                <span data-content={t('_readmore')} aria-hidden="true" />
                {t('_readmore')}
              </Box>
            </a>
          </motion.div>
        </Col>
      </Row>
    </ItemWrapper>
  )
}

export default FeatureItem
