import { Col, Row } from 'antd'
import styled from 'styled-components'

import FeatureItem from './FeatureItem'

const Wrapper = styled.section`
  position: relative;
  margin-top: -1rem;
  padding: 19px 20px 26px;
  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 19px 0 26px;
  }
  .container {
  }
`
const featureListData = [
  {
    link: '/soei',
    thumbnail: '/images/features/thumbnail1.png',
    title: 'Mạng xã hội sáng tạo hướng tới tương lai',
    description:
      '<p>Soei là sự kết hợp của mạng xã hội Web2, Web3, NFT và các công cụ khác nhau để giúp người dùng tương tác với bạn bè; người sáng tạo, thương hiệu tương tác với cộng đồng của họ mà vẫn đảm bảo quyền sở hữu trí tuệ. Hướng đến hoạt động kinh doanh bền vững và phân phối giá trị công bằng cho người dùng.</p>',
  },
  {
    link: '/edu',

    thumbnail: '/images/features/bg.png',
    title: 'Nền tảng giáo dục kết hợp hợp đồng thông minh',
    description:
      '<p>Soei (Edu) (Soei Education) là một sáng kiến giáo dục giúp kết nối các dự án, công ty khởi nghiệp, hay thương hiệu lâu đời với người dùng Soei và cộng đồng rộng lớn hơn. Chúng tôi sẽ hợp tác với các công ty, dự án để cung cấp cách tiếp cận dễ dàng cho người dùng về các sản phẩm.</p>',
  },
  {
    link: '/eretail',
    thumbnail: '/images/features/thumbnail3.png',
    title: 'Cổng rao vặt trực tuyến tích hợp công nghệ 4.0',
    description:
      '<p>eRetail (Soei Classified advertising with Smart contract) giúp tăng doanh số về kinh doanh, dịch vụ của cá nhân, tổ chức hoặc doanh nghiệp kết hợp sử dụng công nghệ chuỗi khối, NFT, định danh nhằm tăng khả năng an toàn trong quá trình giao dịch.</p>',
  },
]

const Features = (props) => {
  return (
    <Wrapper {...props} className="container">
      <Row gutter={[10, 120]}>
        {featureListData &&
          featureListData.map((item, index) => (
            <Col span={24}>
              <div className="item-container">
                <FeatureItem data={item} index={index} />
              </div>
            </Col>
          ))}
      </Row>
    </Wrapper>
  )
}

export default Features
