/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Box } from 'widgets'

const Wrapper = styled.section`
  position: relative;
  z-index: 2;
  padding: 130px 0;
  margin-top: 3rem;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 5rem;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 130px 0;
  }
  color: #fff;
  background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 118.01%), url('images/home/vision-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 82%;
  .vision-title {
    color: var(--wh, var(--Trng, #fff));
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: normal;
    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 55px;
      line-height: 62px;
    }
  }

  .vision-description {
    color: var(--wh, var(--Trng, #fff));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 16px;

    margin-top: 30px;
    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 25px;
    }
  }

  .left-content {
    max-width: 633px;
    width: 100%;
  }
`

const Vision = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Box className="container">
        <Box className="left-content">
          <Box className="vision-title">{t('Tầm nhìn của chúng tôi')}</Box>

          <Box className="vision-description">
            {t(
              'Tầm nhìn của Soei Group trở thành ngôi nhà chung của mọi cá nhân và doanh nghiệp, kích thích phát triển và thúc đẩy xu hướng sử dụng, giao dịch các tài sản số, tài sản thực được mã hóa thông qua việc kết hợp công nghệ mới nhất.',
            )}
          </Box>

          <Box className="vision-description">
            {t(
              '"Tạo nên thế giới không biên giới, nơi mà tất cả mọi người cùng kết nối, cùng trao đổi và cùng đầu tư mà không có bất kỳ rào cản nào"',
            )}
          </Box>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default Vision
