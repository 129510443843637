import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import Image from 'next/image'

import animationData from '../../../lotties/logo.json'

const Wrapper = styled.section`
  padding: 4rem 0 5rem;
  z-index: 5;
  position: relative;
  border-radius: 20px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 4rem 0 7rem;
  }

  p {
    color: var(--Black, var(--en, #000));
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    width: 100%;
    max-width: 758px;
    margin: 11px auto 0 auto;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 32px;
      line-height: 43px;
    }
  }

  .logo-center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--en, #000);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    div {
      margin-left: -8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
    }
    video {
      position: relative;
      z-index: 1;
      zoom: 0.08;
    }
  }
`

const Introduce = (props) => {
  const { t } = useTranslation()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Wrapper {...props}>
      <div className="container">
        <div className="logo-center">
          {/* <Lottie options={defaultOptions} width={175} /> */}
          <Image src="/images/logo.svg" alt="" width={175} height={67} />
        </div>
        <p>
          {t(
            'Soei Group xây dựng, phát triển và ứng dụng các công nghệ giúp mọi người kết nối, học tập, phát triển cộng đồng và doanh nghiệp.',
          )}
        </p>
      </div>
    </Wrapper>
  )
}

export default Introduce
